import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="theme"
export default class extends Controller {
  static values = {
    mode: String
  }
  change(event) {
    event.preventDefault()

    let cookieValue = getCookie("theme")


    // const cookieValue = document.cookie
    //   .split("; ")
    //   .find((row) => row.startsWith("theme="))
    //   ?.split("=")[1];

    // console.log(cookieValue)
    let theme

    if(cookieValue == null || cookieValue == "light") {
      theme = "dark"
    } else {
      theme = "light"
    }
    
    
    // let theme = this.modeValue
    // document.cookie = "theme=${theme}; SameSite=None; Secure"
    document.cookie = `theme=${theme};path=/`
    document.documentElement.setAttribute("data-theme", theme)
    
  }

  
}

function getCookie(name) {
  var match = document.cookie.match(RegExp('(?:^|;\\s*)' + name + '=([^;]*)')); 
  return match ? match[1] : null;
}