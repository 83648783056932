import { Controller } from "@hotwired/stimulus"
import Choices from 'choices.js';
window.choices = Choices;


// Connects to data-controller="select2"
export default class extends Controller {
  connect() {
    const element = document.querySelector('#pickchest');
    const choices = new Choices(element, {
      removeItems: true,
      removeItemButton: true,
      placeholder: true,
    });
    const element2 = document.querySelector('#pickchest2');
    const choices2 = new Choices(element2, {
      removeItems: true,
      removeItemButton: true,
      placeholder: true,
    });
  }
}
