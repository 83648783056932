import { Controller } from "@hotwired/stimulus"

 export default class extends Controller {
  
    disableShowLinks(event){
        const allShowLinks = document.querySelectorAll(".show-token-link");
        allShowLinks.forEach(link => {
            link.classList.add("disabled");
    });
    }

    enableShowLinks(event){
        const showLinks = document.querySelectorAll(".show-token-link");
        showLinks.forEach(link => {
            link.classList.remove("disabled");
    });
    }
 }   