import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

// Connects to data-controller="coin"
export default class extends Controller {
  static targets = [ "links", "template" ]
  connect() {
    
  }
  toggle() {
    
    alert("testing");
  }
  renderPartial() {
    console.log("Rendering partial");

    Rails.ajax({
      url: "coins/coin_fields",
      type: "get",
      success: (data) => {
      document.getElementById("coins-container").innerHTML = data;
      },
    });
  }
  
  add_association(event) {
    event.preventDefault()
    var template = document.querySelector('#table');
    var content = template.innerHTML.replace(/NEW_RECORD/g, new Date().getTime());
    this.linksTarget.insertAdjacentHTML('beforebegin', content);
    //$(.links).insertAdjacentHTML('beforebegin', content);
    //console.log(content);
    
  }

  remove_association(event) {
    event.preventDefault()
    let wrapper = event.target.closest(".nested-fields")
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }
  }

  enableEditLinks(event){
    const editLinks = document.querySelectorAll(".edit-coin-link");
    editLinks.forEach(link => {
        link.classList.remove("disabled");
    });
  }
};
