import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
    disableEditLinks(event){
        const allEditLinks = document.querySelectorAll(".edit-drawer-link");
        allEditLinks.forEach(link => {
            link.classList.add("disabled");
    });
    }

    disableCoinEditLinks(event){
        const allEditLinks = document.querySelectorAll(".edit-coin-link");
        allEditLinks.forEach(link => {
            link.classList.add("disabled");
    });
    }

    enableEditLinks(event){
        const editLinks = document.querySelectorAll(".edit-drawer-link");
        editLinks.forEach(link => {
            link.classList.remove("disabled");
    });
    }
}    
