import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
    disableEditLinks(event){
    this.element.closest(".edit-chest-links").querySelectorAll(".edit-chest-link").forEach(link => {
        if (link !== this.element) {
            link.classList.add("disabled");
            //link.href = '';
            //link.setAttribute("href", "#");
            //link.setAttribute('data-disabled', 'true');
        }
    });
    }

    enableEditLinks(event){
        const editLinks = document.querySelectorAll(".edit-chest-link");
        editLinks.forEach(link => {
            link.classList.remove("disabled");
    });
    }
}    